import React from 'react'
import { css } from 'styled-components'
import { device } from '../styles/devices'

const Quote = ({ children, author }) => (
  <div
    css={css`
      margin: 0 auto;
    `}
  >
    <blockquote
      css={css`
        margin: 0;
        color: #646a86;
        font-size: 2rem;

        &:before {
          position: absolute;
          margin-top: -55px;
          font-family: 'Baloo', cursive;
          content: '“';
          font-style: normal;
          color: #fff1c6;
          font-size: 8rem;
        }

        & > div {
          margin-top: 25px;
          text-align: right;
          font-size: 1.6rem;
          color: #08123e;
        }

        @media ${device.tablet} {
          margin: 0 auto;
          width: 600px;
          font-size: 3rem;

          &:before {
            margin-top: -40px;
            margin-left: -60px;
            font-size: 10rem;
          }

          & > div {
            font-size: 1.8rem;
          }
        }
      `}
    >
      {children}
      {author && <div>— {author}</div>}
    </blockquote>
  </div>
)

export default Quote
