import React from 'react'
import { css } from 'styled-components'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Quote from '../components/quote'
import FooterIndex from '../components/footerIndex'

import trainingImage from '../images/training.svg'
import { device } from '../styles/devices'

const WorkshopsPage = () => (
  <Layout>
    <SEO title="Workshops" />
    <div
      className="grid"
      css={css`
        & > div:not(:last-child) {
          margin-bottom: 50px;

          &:first-child {
            margin-top: 50px;
          }
        }

        @media ${device.tablet} {
          & > div:not(:last-child) {
            margin-bottom: 40px;

            &:first-child {
              margin-top: 50px;
            }
          }
        }
      `}
    >
      <div className="grid-contained">
        <Quote>
          Neue Technologien, neue Teammitglieder, neue Strategien - <br />
          wir unterstützen Sie und Ihr Team bei der Veränderung.
        </Quote>
      </div>

      <div className="grid-contained">
        <div
          css={css`
            & p {
              margin-bottom: 20px;
              font-size: 1.8rem;
              line-height: 33.18px;
              letter-spacing: -0.004em;
            }

            & img {
              margin-top: 50px;
              /* flex: 0 0 600px;
              margin-left: 275px; */
            }

            @media ${device.laptop} {
              display: flex;

              & img {
                margin-left: 100px;
                width: 350px;
                height: 350px;
              }
            }
          `}
        >
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: center;
            `}
          >
            <div>
              <h2
                css={css`
                  display: inline-block;
                  position: relative;
                  font-size: 4rem;
                  margin-bottom: 40px;
                  background: linear-gradient(0, #fff1c6 35px, transparent 0);
                  &:before {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    left: -18px;
                    background-color: transparent;
                    border-bottom: 35px solid transparent;
                    border-right: 18px solid #fff1c6;
                  }
                  &:after {
                    content: ' ';
                    position: absolute;
                    bottom: 0;
                    background-color: transparent;
                    border-top: 35px solid transparent;
                    border-left: 18px solid #fff1c6;
                  }
                `}
              >
                Workshops
              </h2>
            </div>
            <p>
              In unseren Trainings und Workshops vermitteln wir praxisbezogenes
              und anwendbares Wissen mit Aha-Effekten. Lernen durch Erfahrung,
              selbst ausprobieren und erleben, steht für uns im Vordergrund.
            </p>
            <p>
              Dabei richten wir die Inhalte speziell an Ihren Bedürfnissen aus,
              damit Sie den gewünschten Mehrwert erzielen. Ziel ist die
              nachhaltige Entwicklung Ihrer Mitarbeitenden.
            </p>
          </div>
          <div
            css={css`
              text-align: center;
            `}
          >
            <img src={trainingImage} />
          </div>
        </div>
      </div>

      <div
        className="grid-contained"
        css={css`
          & > div {
            margin: 0 auto;
            width: fit-content;
          }

          & h2 {
            margin-top: 18px;
            font-size: 2.8rem;
          }

          & ul {
            margin-left: 1.8rem;
            list-style: disc;

            & > li {
              line-height: 33.18px;

              & > ul {
                list-style: circle;
              }
            }
          }
        `}
      >
        <div>
          <h2>Unser Angebot</h2>
          <ul>
            <li>
              Kompetenzworkshops
              <ul>
                <li>Kommunikation Basics</li>
                <li>Soft Skills</li>
                <li>Kommunikation und Digitalisierung</li>
                <li>Kommunikation und Diversität</li>
                <li>Führungskräftetraining</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <FooterIndex />
  </Layout>
)

export default WorkshopsPage
